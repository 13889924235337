
import { defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import { Modal } from "bootstrap";
import ShowNotification from "@/components/modals/ShowNotification.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    ShowNotification
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
  data(){
    const notificationInfo = [];

    return {
      notificationInfo
    }
  },
  methods: {
    updateNotificationInfo(notification){
      this.notificationInfo = notification;
      
      const modal = new Modal(document.getElementById("showNotificationModal"));
      modal.show();
    }
  }
});
