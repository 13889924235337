const DocMenuConfig = [
  {
    pages: [
      {
        sectionTitle: "Administration",
        route: "/admin",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Users",
            route: "/admin/users/",
          },
          {
            heading: "Organisations",
            route: "/admin/organisations/",
          },
          {
            heading: "Tax Settings",
            route: "/admin/tax-settings/",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
