
import { defineComponent } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTUserMenu,
  },
  data() {
    const store = useStore();
    const user = {
      name: "Anonymous",
      image_url: "media/avatars/blank.png",
    };

    return {
      store,
      user,
      unreadNotifications: [],
      alertNotifications: false
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_AUTH_USER).then(() => {
        const data = this.store.getters.getProfile;
        this.user = Object.assign(this.user, data);

        if (data.user_raws.length > 0) {
          for (let index = 0; index < data.user_raws.length; index++) {
            if (data.user_raws[index].type == "profile") {
              if (data.user_raws[index].raw.name !== "") {
                this.user.name = data.user_raws[index].raw.name;
              }
            }
          }
        }
      });
      this.getNotifications()
    },
    getNotifications(){
      this.store.dispatch(Actions.GET_UNREAD_NOTIFICATIONS).then(() => {
        this.unreadNotifications = this.store.getters.getNotifications;
        if(this.unreadNotifications.length > 0){
          this.alertNotifications = true;
        } else {
          this.alertNotifications = false;
        }
      });
    },
    handleModalNotification(notification){
      this.$emit("updateNotificationInfo", notification);
    }
  },
  async mounted() {
    this.init();
  },
});
