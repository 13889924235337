
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "notifications-menu",
  components: {
  },
  props: ["notifications"],
  data() {
    const store = useStore();
    return {
      store,
    }
  },
  setup(props) {
    const getNotification = computed(() => {
      return props.notifications;
    });

    const getTotalNotification = computed(() => {
      return props.notifications.length ?? 0
    });

    return {
      getNotification,
      getTotalNotification
    };
  },
  methods: {
    showNotification(id, index) {
      this.store.dispatch(Actions.UPDATE_NOTIFICAITON_AS_READ, { id: id }).then(() => {
        this.$emit("getNotifications");
      });

      this.$emit("handleModalNotification", this.getNotification[index])
    }
  }
});
