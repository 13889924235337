const DocMenuConfig = [
  {
    pages: [
      // {
      //   heading: "dashboard",
      //   route: "/dashboard",
      //   svgIcon: "media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      // },
      // {
      //   sectionTitle: "Administration",
      //   route: "/admin",
      //   svgIcon: "media/icons/duotune/finance/fin007.svg",
      //   fontIcon: "bi-person",
      //   sub: [
      //     {
      //       heading: "Users",
      //       route: "/admin/users/",
      //     },
      //     {
      //       heading: "Organisations",
      //       route: "/admin/organisations/",
      //     },
      //   ],
      // },
      // {
      //   sectionTitle: "Dashboard",
      //   route: "/dashboard",
      //   svgIcon: "media/icons/duotune/finance/fin007.svg",
      //   fontIcon: "bi-person",
      //   sub: [
      //     {
      //       heading: "Financial Advisor",
      //       route: "/dashboard/financial-advisor/",
      //     },
      //     {
      //       heading: "Branch Head",
      //       route: "/dashboard/branch-head/",
      //     },
      //     {
      //       heading: "Head of Department",
      //       route: "/dashboard/head-of-department/",
      //     },
      //   ],
      // },
      // {
      //   heading: "layoutBuilder",
      //   route: "/builder",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      //   fontIcon: "bi-layers",
      // },
      {
        sectionTitle: "Customers",
        route: "/customers",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "All",
            route: "/customers/cbs/",
          },
          {
            heading: "URUS",
            route: "/customers/cbs/urus/",
          },
          {
            heading: "FIRST",
            route: "/customers/cbs/first/",
          },
        ],
      },
    ],
  },
  {
    heading: "Financial Planning",
    route: "/financial-planning",
    pages: [
      {
        heading: "factFindingSheet",
        route: "/financial-planning/fact-finding-sheet",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "retirementPlanning",
        route: "/financial-planning/retirement-planning",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "investmentPlanning",
        route: "/financial-planning/investment-planning",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "willPlanning",
        route: "/financial-planning/will-planning",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "taxPlanning",
        route: "/financial-planning/tax-planning",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "riskPlanning",
        route: "/financial-planning/risk-planning",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
      },

      // {
      //   sectionTitle: "financialSimulations",
      //   route: "/simulation",
      //   svgIcon: "media/icons/duotune/finance/fin007.svg",
      //   fontIcon: "bi-person",
      //   sub: [
      //     // {
      //     //   heading: "tax",
      //     //   route: "/financial-planning/simulation/tax",
      //     // },
      //     {
      //       heading: "retirementSimulation",
      //       route: "/financial-planning/retirement-simulation",
      //     }
      //   ],
      // },
    ],
  },

  {
    heading: "Calculators",
    route: "/financial-calculators",
    pages: [
      {
        sectionTitle: "financialCalculators",
        route: "/financial-calculators",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "hirePurchase",
            route: "/financial-calculators/hire-purchase",
          },
          {
            heading: "creditCard",
            route: "/financial-calculators/credit-card",
          },
          {
            heading: "housingLoan",
            route: "/financial-calculators/housing-loan",
          },
          {
            heading: "personalLoan",
            route: "/financial-calculators/personal-loan",
          },
          {
            heading: "Investment Projections",
            route: "/financial-calculators/investment-projections",
          },
          {
            heading: "Future Retirement Savings Projection",
            route: "/financial-calculators/retirement-projections",
          },
        ],
      },
      {
        sectionTitle: "DMP Calculators",
        route: "/dmp-calculators",
        svgIcon: "media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "hirePurchase",
            route: "/dmp-calculators/hire-purchase",
          },
          {
            heading: "creditCard",
            route: "/dmp-calculators/credit-card",
          },
          {
            heading: "housingLoan",
            route: "/dmp-calculators/housing-loan",
          },
          {
            heading: "personalLoan",
            route: "/dmp-calculators/personal-loan",
          },
        ],
      },
    ],
  },
  {
    heading: "Financial Statements",
    route: "/financial-statements",
    pages: [
      {
        sectionTitle: "Household",
        route: "/hh",
        svgIcon: "media/icons/duotune/communication/com005.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "householdCashFlow",
            route: "/financial-statements/hh/cash-flow",
          },
          {
            heading: "netWorthStatement",
            route: "/financial-statements/hh/net-worth-statement",
          },
        ],
      },
      // {
      //   sectionTitle: "SME",
      //   route: "/sme",
      //   svgIcon: "media/icons/duotune/communication/com005.svg",
      //   fontIcon: "bi-person",
      //   sub: [
      // {
      //   heading: "dailyCashFlow",
      //   route: "/financial-statements/daily-cash-flow",
      // },
      // {
      //   heading: "statementCashFlow",
      //   route: "/financial-statements/cash-flow/sme-cash-flow",
      // },
      // {
      //   heading: "sales",
      //   route: "/financial-statements/sme/sales",
      // },
      // {
      //   heading: "Cost of Sales",
      //   route: "/financial-statements/sme/cost-of-sales",
      // },
      // {
      //   heading: "Other Income & Expenses",
      //   route: "/financial-statements/sme/other-income-expenses",
      // },
      // {
      //   heading: "Tax & Depreciation",
      //   route: "/financial-statements/sme/tax-depreciation",
      // },
      // {
      //   heading: "Profit & Loss",
      //   route: "/financial-statements/sme/profit-loss",
      // },
      // {
      //   heading: "Balance Sheet",
      //   route: "/financial-statements/balance-sheet/balance-sheet",
      // },
      //   ],
      // },
    ],
  },
  {
    heading: "Financial Scoring",
    route: "/financial-scoring-hh",
    svgIcon: "media/icons/duotune/communication/com006.svg",
    fontIcon: "bi-person",
    pages: [
      {
        sectionTitle: "Household",
        route: "/financial-scoring-hh",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Financial Scoring",
            route: "/financial-scoring-hh-preview",
          },
        ],
      },
    ],
  },

  // {
  //   heading: "pages.organisation.title",
  //   route: "/organisation",
  //   svgIcon: "media/icons/duotune/communication/com006.svg",
  //   fontIcon: "bi-person",
  //   pages: [
  //     {
  //       sectionTitle: "pages.organisation.title",
  //       route: "/organisation",
  //       svgIcon: "media/icons/duotune/communication/com006.svg",
  //       fontIcon: "bi-person",
  //       sub: [
  //         {
  //           heading: "pages.organisation.list",
  //           route: "/organisation/list",
  //         },
  //         {
  //           heading: "pages.organisation.add",
  //           route: "/organisation/add",
  //         },
  //       ]
  //     }
  //   ],
  // },
  {
    heading: "Financial Skills",
    route: "/financial-skills",
    svgIcon: "media/icons/duotune/communication/com006.svg",
    fontIcon: "bi-person",
    pages: [
      {
        sectionTitle: "Financial Skills",
        route: "/financial-skills",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Financial Skills Modules",
            route: "/financial-skills/modules",
          },
          {
            heading: "Course Lists",
            route: "/financial-skills/course-lists",
          },
        ],
      },
    ],
  },
  // {
  //   heading: "Personalised Financial Plan Report",
  //   route: "/pfp-cover-letter",
  //   svgIcon: "media/icons/duotune/communication/com006.svg",
  //   fontIcon: "bi-person",
  //   pages: [
  //     {
  //       sectionTitle: "PFP Cover Letter",
  //       route: "/pfp-cover-letter",
  //       svgIcon: "media/icons/duotune/communication/com006.svg",
  //       fontIcon: "bi-person",
  //       sub: [
  //         {
  //           heading: "PFP Cover Letter",
  //           route: "/pfp-cover-letter",
  //         },
  //       ]
  //     }
  //   ],
  // },

  // {
  //   heading: "ccris",
  //   route: "/ccriss/credit-report",
  //   pages: [
  //     {
  //       heading: "CCRIS ",
  //       route: "/ccriss/credit-report",
  //       svgIcon: "media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },
  // {
  //   heading: "listOfCoordinator",
  //   route: "/apps",
  //   pages: [
  //     {
  //       sectionTitle: "List Of Coordinator",
  //       route: "/customers",
  //       svgIcon: "media/icons/duotune/finance/fin006.svg",
  //       fontIcon: "bi-printer",
  //       sub: [
  //         {
  //           heading: "SME Head",
  //           route: "/list-of-coordinator/list-of-coordinator",
  //         },
  //         {
  //           heading: "Coordinator Form",
  //           route: "/list-of-coordinator/Coordinator-form",
  //         },
  //         {
  //           heading: "customerDetails",
  //           route: "/apps/customers/customer-details",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   heading: "financialEducation",
  //   route: "/apps",
  //   pages: [
  //     {
  //       sectionTitle: "Financial Education",
  //       route: "/financialEducation",
  //       svgIcon: "media/icons/duotune/finance/fin006.svg",
  //       fontIcon: "bi-printer",
  //       sub: [
  //         {
  //           heading: "financialEducationList",
  //           route: "/financial-education/financial-education-list",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   heading: "financialHealthCheck",
  //   route: "/financial-health-check/financial-health-check",
  //   pages: [
  //     {
  //       heading: "Financial Health Check ",
  //       route: "/financial-health-check/financial-health-check",
  //       svgIcon: "media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },
  // {
  //   heading: "profilingQuestionnaire",
  //   route: "/profiling-questionnaire/profiling-questionnaire",
  //   pages: [
  //     {
  //       heading: "Profiling Questionnaire ",
  //       route: "/profiling-Questionnaire/profiling-Questionnaire",
  //       svgIcon: "media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },
  // {
  //   heading: "financialResolution",
  //   route: "/financial-resolution/financial-resolution",
  //   pages: [
  //     {
  //       heading: "Financial Resolution ",
  //       route: "/financial-resolution/financial-resolution",
  //       svgIcon: "media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },
];

export default DocMenuConfig;
