
import { defineComponent, computed } from "vue";
export default defineComponent({
    name: "show-notification-modal",
    props: ['notificationInfo'],
    setup(props){
      const notification = computed(() => {
        return props.notificationInfo;
      });

      return {
        notification
      }
    }
})
